// This hook manages saving and restoring budget filters in local storage
// - Returns stored budget filters and saveFilterState method
//  - See Inspector > Application > Local storage > domain > budgetFilters

import { useContext } from 'react';
import * as R from 'ramda';

import {
  BasicBudget,
  BudgetCategory,
  BudgetItemTemplate,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';

export enum FILTER_KEYS {
  CATEGORY = 'CATEGORY',
  ITEMS = 'ITEMS',
  COMPARE = 'COMPARE',
  TRACKING = 'TRACKING',
  CHART = 'CHART',
}

export const STORAGE_KEY = 'budgetFilters';

export const getFilterKey = (key: FILTER_KEYS, budgetId: string): string =>
  `${budgetId}_${key}`;

export const deleteBudgetFilters = (budgetId: string) => {
  const existing = localStorage.getItem(STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(STORAGE_KEY))
    : {};
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...R.omit(
        Object.keys(FILTER_KEYS).map(filter =>
          getFilterKey(filter as FILTER_KEYS, budgetId),
        ),
        existing,
      ),
    }),
  );
};

const omitTypename = item => R.omit(['__typename'], item);

export const useBudgetFilterStorage = (budgetId?: string) => {
  const {
    budget,
    categoryFilters,
    budgetItemTemplateFilters,
    comparisonBudgets,
    showChart,
    showTracking,
  } = useContext(BudgetDetailContext);

  const existing = localStorage.getItem(STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(STORAGE_KEY))
    : {};

  const existingCategoryFilters: BudgetCategory[] =
    existing[getFilterKey(FILTER_KEYS.CATEGORY, budgetId)] || [];

  const existingBudgetItemFilters: BudgetItemTemplate[] =
    existing[getFilterKey(FILTER_KEYS.ITEMS, budgetId)] || [];

  const existingComparisonBudgets: BasicBudget[] =
    existing[getFilterKey(FILTER_KEYS.COMPARE, budgetId)] || [];

  const existingShowTracking = isNilOrEmpty(
    existing[getFilterKey(FILTER_KEYS.TRACKING, budgetId)],
  )
    ? true
    : existing[getFilterKey(FILTER_KEYS.TRACKING, budgetId)];

  const existingShowChart = isNilOrEmpty(
    existing[getFilterKey(FILTER_KEYS.CHART, budgetId)],
  )
    ? true
    : existing[getFilterKey(FILTER_KEYS.CHART, budgetId)];

  // Save filters from existing state
  // - set optional key: value if passed
  // - omit typename on arrays
  const saveFilterState = (key?: FILTER_KEYS, value?) => {
    if (budget?.id) {
      const catValue = key === FILTER_KEYS.CATEGORY ? value : categoryFilters;
      const itemValue =
        key === FILTER_KEYS.ITEMS ? value : budgetItemTemplateFilters;
      const compareValue =
        key === FILTER_KEYS.COMPARE ? value : comparisonBudgets;
      const trackValue = key === FILTER_KEYS.TRACKING ? value : showTracking;
      const chartValue = key === FILTER_KEYS.CHART ? value : showChart;

      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          ...existing,
          [getFilterKey(FILTER_KEYS.CATEGORY, budget.id)]: catValue.map(
            omitTypename,
          ),
          [getFilterKey(FILTER_KEYS.ITEMS, budget.id)]: itemValue.map(
            omitTypename,
          ),
          [getFilterKey(FILTER_KEYS.COMPARE, budget.id)]: compareValue.map(
            omitTypename,
          ),
          [getFilterKey(FILTER_KEYS.TRACKING, budget.id)]: trackValue,
          [getFilterKey(FILTER_KEYS.CHART, budget.id)]: chartValue,
        }),
      );
    }
  };

  return {
    existingCategoryFilters,
    existingBudgetItemFilters,
    existingComparisonBudgets,
    existingShowTracking,
    existingShowChart,
    saveFilterState,
  };
};
