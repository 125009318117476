import React from 'react';

import './requests.css';

const RequestsTab = () => {
  return (
    <div styleName="body-container">
      <div styleName="left-body-pane-fixed">Sidebar</div>
      <div styleName="right-body-pane-fill">Requests</div>
    </div>
  );
};

export default RequestsTab;
