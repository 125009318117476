import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_PORTAL_SCHEMAS,
  SCHEMA_CREATE,
  SCHEMA_DELETE,
  SCHEMA_DUPLICATE,
} from '@atom/graph/schema';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import {
  Schema,
  SchemaCreateInput,
  SchemaSavePayload,
  SchemasConnection,
  SchemasInput,
} from '@atom/types/schema';
import history from '@atom/utilities/history';
import { getPortals } from '@atom/utilities/portalUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SchemaPortalHeader from './SchemaPortalHeader';
import SchemaPortalTable from './SchemaPortalTable';

import './schemaPortal.css';

const schemaTileStyle = {
  deleteModalConfirmButton: {
    backgroundColor: colors.brand.red,
  },
};

const SchemaPortal = () => {
  const preferences = usePreferences();
  const landingPortal = getPortals(preferences)[preferences.landingPortal];

  const [schemaToDelete, setSchemaToDelete] = useState<Partial<Schema>>(null);
  const [duplicateId, setDuplicateId] = useState<string>(null);

  const {
    data: schemaData,
    loading: isLoadingGetSchemas,
    refetch: refetchSchemas,
  } = useQuery<{ schemas: SchemasConnection }, { input: SchemasInput }>(
    GET_PORTAL_SCHEMAS,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: { input: { rootSchemas: true, isPublished: 'true,false' } },
    },
  );

  const [createSchema] = useMutation<
    { schemaCreate: InventorySchemaItem },
    { input: SchemaCreateInput }
  >(SCHEMA_CREATE);

  const [duplicateSchema, { loading: isDuplicateLoading }] = useMutation<
    { schemaDuplicate: InventorySchemaItem },
    { id: string }
  >(SCHEMA_DUPLICATE);

  const [deleteSchema, { loading: isDeleteLoading }] = useMutation<
    { schemaDelete: boolean },
    { id: string }
  >(SCHEMA_DELETE);

  const handleAddSchema = async (payload: SchemaSavePayload) => {
    try {
      const { data } = await createSchema({
        variables: {
          input: {
            name: payload?.name,
            assetType: payload?.name,
            locationType: payload?.locationType,
            colorId: payload?.colorId,
            markerId: payload?.markerId,
            isMaterial: payload?.isMaterial,
            isPublished: false,
          },
        },
      });

      history.push(`/admin/inventoryTypes/${data?.schemaCreate.id}`);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const handleDuplicateSchema = async (id: string) => {
    try {
      const { data } = await duplicateSchema({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: `Created inventory type: ${data?.schemaDuplicate?.name}`,
      });

      setDuplicateId(data.schemaDuplicate.id);
      refetchSchemas();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const handleDeleteSchema = async (id: string) => {
    try {
      await deleteSchema({
        variables: {
          id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted inventory type.',
      });

      setDuplicateId(null);
      refetchSchemas();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const navigateBack = (): void => {
    const state = history.location.state;
    if (R.isNil(state) || R.isEmpty(state)) {
      return history.push(landingPortal.urlPath);
    }
    return history.push('/inventory', state);
  };

  return (
    <div styleName="portal-wrapper">
      <SchemaPortalHeader
        navigateBack={navigateBack}
        addSchema={handleAddSchema}
      />
      <div styleName="portal-content">
        <div styleName="left-pane-container">
          {/* This is blank to match other tables */}
        </div>
        <div styleName="right-pane-container">
          <SchemaPortalTable
            schemas={schemaData?.schemas?.schemas}
            isTableLoading={
              isLoadingGetSchemas || isDuplicateLoading || isDeleteLoading
            }
            handleDuplicateSchema={handleDuplicateSchema}
            setSchemaToDelete={setSchemaToDelete}
            duplicateId={duplicateId}
          />
        </div>
      </div>
      <Modal
        open={!isNilOrEmpty(schemaToDelete)}
        disabled={isDeleteLoading}
        title={`Delete ${schemaToDelete?.name} Inventory Type?`}
        onCancel={() => setSchemaToDelete(null)}
        confirmButtonText="Delete"
        onConfirm={(): void => {
          handleDeleteSchema(schemaToDelete?.id);
          setSchemaToDelete(null);
        }}
        ConfirmButtonProps={{
          style: schemaTileStyle.deleteModalConfirmButton,
        }}
      >
        Deleting this inventory type will also delete all items under it and all
        associated assets.
      </Modal>
    </div>
  );
};

export default SchemaPortal;
