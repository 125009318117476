import React, { useContext } from 'react';

import { Checkbox, Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import BudgetDetailContext from '../BudgetDetailContext';
import { FILTER_KEYS, useBudgetFilterStorage } from '../useBudgetFilterStorage';

import BudgetDetailCategoryFilter from './BudgetDetailCategoryFilter';
import BudgetDetailComparisonFilter from './BudgetDetailComparisonFilter';
import BudgetDetailItemFilter from './BudgetDetailItemFilter';

import '../budgetDetail.css';

const styles = {
  fixedFiltersContainer: {
    position: 'fixed',
    height: '75px',
    marginTop: '110px',
    padding: '1rem 1.5rem',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.neutral.white,
    zIndex: 100,
  },

  filterContainer: {
    padding: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },

  filterLabel: {
    color: colors.neutral.gray,
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },

  separator: {
    height: '2rem',
    width: '1px',
    backgroundColor: colors.neutral.ash,
    display: 'inline-block',
    padding: 0,
    marginLeft: '0.25rem',
  },
  fixedInfoIconButton: {
    padding: '0',
    height: '1.5rem',
  },
  fixedInfoIcon: { fontSize: '0.75rem' },
};

const BudgetDetailFilters = () => {
  const {
    showTracking,
    setShowTracking,
    showExpenditures,
    showChart,
    setShowChart,
  } = useContext(BudgetDetailContext);
  const { saveFilterState } = useBudgetFilterStorage();

  const handleTrackingToggle = () => {
    setShowTracking(prev => {
      saveFilterState(FILTER_KEYS.TRACKING, !prev);
      return !prev;
    });
  };

  const handleChartToggle = () => {
    setShowChart(prev => {
      saveFilterState(FILTER_KEYS.CHART, !prev);
      return !prev;
    });
  };

  return (
    <div style={styles.filterContainer}>
      <div style={styles.filterLabel}>Filters:</div>
      <BudgetDetailCategoryFilter />
      <BudgetDetailItemFilter />
      <div style={styles.separator} />
      <div style={styles.filterLabel}>
        <span>Compare</span>
        <IconButton
          tooltip="Maximum 5 comparison budget selections"
          style={styles.fixedInfoIconButton}
        >
          <Icon style={styles.fixedInfoIcon}>info_outlined</Icon>
        </IconButton>
      </div>
      <BudgetDetailComparisonFilter />
      {showExpenditures && (
        <>
          <div style={styles.separator} />
          <Checkbox
            onChange={handleTrackingToggle}
            checked={showTracking}
            label="Show Tracking"
          />
        </>
      )}
      {showExpenditures && showTracking && (
        <Checkbox
          onChange={handleChartToggle}
          checked={showChart}
          label="Show Chart"
        />
      )}
    </div>
  );
};

export default BudgetDetailFilters;
