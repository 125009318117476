import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_BUDGET,
  GET_BUDGET_OPTIONS,
  GET_BUDGET_SUMMARIES,
  GET_BUDGET_UNIT,
} from '@atom/graph/budget';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Progress } from '@atom/mui';
import {
  BasicBudget,
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetsConnection,
  BudgetsConnectionInput,
  BudgetSummary,
  BudgetSummaryConnection,
  BudgetSummaryConnectionInput,
  BudgetUnit,
  BudgetUnitConnectionInput,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailFilters from './filtersBar/BudgetDetailFilters';
import BudgetDetailBreadcrumbs from './BudgetDetailBreadcrumbs';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailHeader from './BudgetDetailHeader';
import BudgetDetailStatus from './BudgetDetailStatus';
import BudgetDetailUnit from './BudgetDetailUnit';
import { BUDGET_OPTIONS_LIMIT, EditField } from './budgetDetailUtils';
import BudgetFixedHeader from './BudgetFixedHeader';
import { useBudgetFilterStorage } from './useBudgetFilterStorage';

import './budgetDetail.css';

interface Props {
  match: any;
}

export interface FetchParentProps {
  budgetUnitId: string;
  newCategoryIds?: string[];
  newBudgetItemTemplateNames?: string[];
}

const styles = {
  breadcrumbRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const BudgetDetail = ({ match }: Props) => {
  const budgetId = match.params.id;
  const unitId = match.params.unitId;
  const preferences = usePreferences();
  const showExpenditures = R.pathOr(
    false,
    ['budgeting', 'showExpenditures'],
    preferences,
  );
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );
  const [parentBudgetUnit, setParentBudgetUnit] = useState<BudgetUnit>(null);
  const [childBudgetUnits, setChildBudgetUnits] = useState<BudgetUnit[]>(null);
  const [budgetCategories, setBudgetCategories] = useState<BudgetCategory[]>(
    null,
  );

  // navigation options
  const [budgetOptions, setBudgetOptions] = useState<Budget[]>([]);
  const [totalBudgetOptions, setTotalBudgetOptions] = useState<number>(0);
  const [getBudgetOptions, { loading: loadingBudgetOptions }] = useLazyQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGET_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!isNilOrEmpty(data)) {
        const nextOptions: Budget[] = R.pathOr(
          [],
          ['budgetOptions', 'budgets'],
          data,
        );
        const newTotal: number = R.pathOr(
          0,
          ['budgetOptions', 'totalCount'],
          data,
        );
        setBudgetOptions([...(budgetOptions || []), ...nextOptions]);
        setTotalBudgetOptions(newTotal);
      }
    },
  });

  // filters
  const {
    existingCategoryFilters,
    existingBudgetItemFilters,
    existingComparisonBudgets,
    existingShowTracking,
    existingShowChart,
  } = useBudgetFilterStorage(budgetId);

  const [showTracking, setShowTracking] = useState<boolean>(
    showExpenditures && existingShowTracking,
  );
  const [showChart, setShowChart] = useState<boolean>(
    showExpenditures && existingShowChart,
  );

  const [excludeZeroBudgetItems, setExcludeZeroBudgetItems] = useState<boolean>(
    false,
  );
  const [categoryFilters, setCategoryFilters] = useState<BudgetCategory[]>(
    existingCategoryFilters,
  );

  const [budgetItemTemplateFilters, setBudgetItemTemplateFilters] = useState<
    BudgetItemTemplate[]
  >(existingBudgetItemFilters);

  const categoryIds = useMemo(
    () => categoryFilters.map(category => category?.id),
    [categoryFilters],
  );
  const budgetItemTemplateNames = useMemo(
    () => budgetItemTemplateFilters.map(template => template.name),
    [budgetItemTemplateFilters],
  );

  // Budget Comparisons
  const [openComparisonRowId, setOpenComparisonRowId] = useState<string>();
  const [comparisonBudgets, setComparisonBudgets] = useState<BasicBudget[]>(
    existingComparisonBudgets,
  );
  const [budgetSummaries, setBudgetSummaries] = useState<BudgetSummary[]>([]);
  const [budgetOverviewSummaries, setBudgetOverviewSummaries] = useState<
    BudgetSummary[]
  >([]);

  const [fetchSummaries, { loading: loadingSummaries }] = useLazyQuery<
    { budgetSummary: BudgetSummaryConnection },
    { input: BudgetSummaryConnectionInput }
  >(GET_BUDGET_SUMMARIES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const summaries: BudgetSummary[] = R.pathOr(
        [],
        ['budgetSummary', 'budgetSummary'],
        data,
      );
      setBudgetSummaries(summaries);
    },
  });

  // Item Editing
  const [editingItem, setEditingItem] = useState<BudgetItem>();
  const [editingField, setEditingField] = useState<EditField>();
  const [itemHoverId, setItemHoverId] = useState<string>();
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
    new Set([]),
  );

  // Budget Status Bulk Select
  const [selectedUnits, setSelectedUnits] = useState<Set<string>>(new Set([]));

  // Top-level call to get budget for this page
  const { data: budgetData } = useQuery(GET_BUDGET, {
    variables: {
      id: match.params?.id,
    },
    fetchPolicy: 'network-only',
  });
  const budget: Budget = useMemo(() => R.pathOr(null, ['budget'], budgetData), [
    budgetData,
  ]);

  const [getParentUnit, { loading: loadingParentUnit }] = useLazyQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setParentBudgetUnit(R.pathOr(null, ['budgetUnit'], data));
    },
  });

  const fetchParentUnit = (params: FetchParentProps) => {
    setParentBudgetUnit(null);
    const { budgetUnitId, newCategoryIds, newBudgetItemTemplateNames } = params;
    getParentUnit({
      variables: {
        input: {
          budgetId,
          budgetUnitId,
          categoryIds: newCategoryIds ? newCategoryIds : categoryIds,
          budgetItemTemplateNames: newBudgetItemTemplateNames
            ? newBudgetItemTemplateNames
            : budgetItemTemplateNames,
        },
      },
    });
  };

  // Effect initializes page after budget data is loaded
  useEffect(() => {
    if (!isNilOrEmpty(budget)) {
      setBudgetOptions([]);
      setShowTracking(showExpenditures && existingShowTracking);
      setShowChart(showExpenditures && existingShowChart);
      setComparisonBudgets(existingComparisonBudgets);
      setCategoryFilters(existingCategoryFilters);
      setBudgetItemTemplateFilters(existingBudgetItemFilters);
      fetchParentUnit({
        budgetUnitId: unitId || budget?.rootBudgetUnitId,
        newCategoryIds: existingCategoryFilters.map(({ id }) => id),
        newBudgetItemTemplateNames: existingBudgetItemFilters.map(
          ({ name }) => name,
        ),
      });
      getBudgetOptions({
        variables: {
          input: {
            templateId: budget.templateId,
            page: 1,
            limit: BUDGET_OPTIONS_LIMIT,
            sortBy: 'name,asc',
          },
        },
      });
    }
  }, [budget]);

  return (
    <BudgetDetailContext.Provider
      value={{
        budget,
        parentBudgetUnit,
        setParentBudgetUnit,
        childBudgetUnits,
        setChildBudgetUnits,
        budgetCategories,
        setBudgetCategories,
        categoryFilters,
        categoryIds,
        setCategoryFilters,
        budgetItemTemplateFilters,
        setBudgetItemTemplateFilters,
        budgetItemTemplateNames,
        editingItem,
        setEditingItem,
        editingField,
        setEditingField,
        expandedCategories,
        setExpandedCategories,
        excludeZeroBudgetItems,
        setExcludeZeroBudgetItems,
        loadingParentUnit,
        comparisonBudgets,
        setComparisonBudgets,
        budgetSummaries,
        setBudgetSummaries,
        budgetOverviewSummaries,
        setBudgetOverviewSummaries,
        openComparisonRowId,
        setOpenComparisonRowId,
        fetchSummaries,
        loadingSummaries,
        itemHoverId,
        setItemHoverId,
        showTracking,
        setShowTracking,
        showExpenditures,
        showChart,
        setShowChart,
        loadingBudgetOptions,
        totalBudgetOptions,
        setTotalBudgetOptions,
        budgetOptions,
        setBudgetOptions,
        getBudgetOptions,
        selectedUnits,
        setSelectedUnits,
        fetchParentUnit,
      }}
    >
      <>
        {isNilOrEmpty(budget) ? (
          <Progress />
        ) : (
          <>
            <BudgetDetailHeader />
            <div styleName="container">
              <BudgetFixedHeader>
                <div style={styles.breadcrumbRow}>
                  <BudgetDetailBreadcrumbs />
                  {showApprovalFlow && <BudgetDetailStatus />}
                </div>
                <BudgetDetailFilters />
              </BudgetFixedHeader>
              <BudgetDetailUnit />
            </div>
          </>
        )}
      </>
    </BudgetDetailContext.Provider>
  );
};

export default BudgetDetail;
