// This Component manages the editing state for all budget item rows
import React, { useContext, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { UPDATE_BUDGET_ITEM, UPDATE_BUDGET_UNITS } from '@atom/graph/budget';
import { usePreferences } from '@atom/hooks/usePreferences';
import { ListTable, Modal, Snackbar } from '@atom/mui';
import {
  BudgetCategory,
  BudgetItem,
  BudgetItemUpdateInput,
  BudgetStatus,
  BudgetUnitUpdateInput,
} from '@atom/types/budget';
import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import {
  EditField,
  FIELD_KEYS,
  FIELD_NAMES,
  isStatusLocked,
  ITEM_ROW_HEIGHT,
  MAX_ITEM_VALUE,
  MAX_ROWS_PER_CATEGORY,
} from '../budgetDetailUtils';

import BudgetDetailItemRow from './BudgetDetailItemRow';

import '../budgetDetail.css';

const { TableBody } = ListTable;

interface Props {
  budgetCategory: BudgetCategory;
  getBudgetCategories: (input: any) => void;
}

const BudgetDetailItemTable = ({
  budgetCategory,
  getBudgetCategories,
}: Props) => {
  const {
    budget,
    editingItem,
    setEditingItem,
    editingField,
    setEditingField,
    parentBudgetUnit,
    setParentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
    fetchParentUnit,
  } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );
  const showAuthorizedBudget = R.pathOr(
    false,
    ['budgeting', 'showAuthorizedBudget'],
    preferences,
  );
  const statusLocked = isStatusLocked(budget?.id);

  const [confirmField, setConfirmField] = useState<EditField>();
  const [confirmItem, setConfirmItem] = useState<BudgetItem>();

  const [confirmDraftFromEdit, setConfirmDraftFromEdit] = useState<boolean>(
    false,
  );
  const [editValue, setEditValue] = useState<string>();
  const [previousEditValue, setPreviousEditValue] = useState(0);

  const [updateBudgetItem, { loading: loadingItemUpdate }] = useMutation<
    { budgetItemUpdate: BudgetItem },
    { input: BudgetItemUpdateInput }
  >(UPDATE_BUDGET_ITEM);

  const [updateBudgetStatus] = useMutation<{
    input: BudgetUnitUpdateInput;
  }>(UPDATE_BUDGET_UNITS);

  const canEdit = useMemo(() => {
    const canEditUnit = hasAccess(
      PolicyAction.EDIT_BUDGET_ITEM,
      parentBudgetUnit?.actionsOnBudgetItems,
    );
    switch (parentBudgetUnit?.status) {
      case BudgetStatus.APPROVED:
        return (
          canEditUnit &&
          hasAccess(
            PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED,
            parentBudgetUnit?.actionsOnApprovalProcess,
          )
        );

      case BudgetStatus.SUBMITTED:
        return (
          canEditUnit &&
          hasAccess(
            PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED,
            parentBudgetUnit?.actionsOnApprovalProcess,
          )
        );

      default:
        return canEditUnit;
    }
  }, [parentBudgetUnit]);

  const enableFieldEdit = (field: EditField, budgetItem: BudgetItem) => {
    setEditValue(budgetItem[FIELD_KEYS[field]]);
    setEditingItem(budgetItem);
    setEditingField(field);
  };

  const handleInputCellClick = (field: EditField, budgetItem: BudgetItem) => {
    if (canEdit) {
      if (
        showApprovalFlow &&
        !statusLocked &&
        parentBudgetUnit?.status !== BudgetStatus.DRAFT
      ) {
        setConfirmDraftFromEdit(true);
        setConfirmField(field);
        setConfirmItem(budgetItem);
      } else {
        enableFieldEdit(field, budgetItem);
      }
    }
  };

  const updateField = async () => {
    // Ensure all empty values passed to value field are 0
    const sanitizedValue =
      editingField === 'VALUE' && isNilOrEmpty(editValue) ? 0 : editValue;

    try {
      // if tenant is using budget status and this is a non-draft, warn user
      // before resetting to draft status
      const updatedItemData = await updateBudgetItem({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetItemId: editingItem?.id,
            budgetValue: editingItem.budgetValue,
            comment: editingItem.comment,
            [FIELD_KEYS[editingField]]: sanitizedValue,
          },
        },
      });

      // Re-calculate overview totals when updating the value:
      // - calculating on FE to avoid page refresh between updates
      if (editingField === 'VALUE') {
        const newTotal =
          parentBudgetUnit.totalBudget +
          (parseFloat(editValue || '0') - previousEditValue);
        const newRemaining =
          newTotal -
          (parentBudgetUnit.actualBudget + parentBudgetUnit.fixedCosts);
        const newDifference = newTotal - parentBudgetUnit?.authorizedBudget;

        setParentBudgetUnit({
          ...parentBudgetUnit,
          totalBudget: newTotal,
          remaining: newRemaining,
          ...(showAuthorizedBudget ? { difference: newDifference } : {}),
        });
      }

      getBudgetCategories({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            budgetItemTemplateNames,
            excludeZeroBudgetItems,
          },
        },
      });

      const getNewValue = () =>
        numberToLocaleString(
          updatedItemData.data.budgetItemUpdate[FIELD_KEYS[editingField]],
        );

      const messagePre = `Successfully updated ${FIELD_NAMES[editingField]} on ${editingItem.name}`;
      const messagePost =
        editingField === 'VALUE'
          ? `: from ${numberToLocaleString(
              editingItem.budgetValue,
            )} to ${getNewValue()}`
          : '';
      const message = messagePre + messagePost;

      Snackbar.info({
        message,
      });
    } catch (error) {
      const message =
        error?.networkError?.statusCode === 400 && editingField === 'VALUE'
          ? 'Please enter only numeric characters and periods in budget values.'
          : 'An error occurred while updating your budget. Please Try Again.';
      Snackbar.error({ message });
    }
    setEditingItem(null);
  };

  const handleRevertBudget = async () => {
    await updateBudgetStatus({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitIds: [parentBudgetUnit?.id],
          status: BudgetStatus.DRAFT,
          action:
            parentBudgetUnit.status === BudgetStatus.APPROVED
              ? PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED
              : PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED,
        },
      },
    });
    fetchParentUnit({ budgetUnitId: parentBudgetUnit.id });
    setConfirmDraftFromEdit(false);
    enableFieldEdit(confirmField, confirmItem);
  };

  const handleSaveField = () => {
    // Check validation cases
    switch (true) {
      case editingField === 'VALUE' && parseFloat(editValue) < 0:
        Snackbar.error({
          message: 'Please enter positive numbers for budget amounts.',
        });
        setEditingField(null);
        return;
      case editingField === 'VALUE' &&
        parseFloat(editValue) > MAX_ITEM_VALUE.VALUE:
        Snackbar.error({
          message: MAX_ITEM_VALUE.ERROR,
        });
        setEditingField(null);
        return;
      case editingItem[FIELD_KEYS[editingField]] === editValue:
        // Do not save unchanged values
        setEditingField(null);
        return;
      case editingField === 'VALUE' &&
        !isNilOrEmpty(editValue.match(/^[a-zA-Z]+$/)):
        // Do not allow alphabetical characters
        Snackbar.error({
          message:
            'Please enter only numeric characters and periods in budget values.',
        });
        setEditingField(null);
        return;
      default:
        break;
    }
    updateField();
  };

  const handleKeyUp = event => {
    // detect and handle enter key to trigger save
    if (event.keyCode === 13) {
      handleSaveField();
    }
  };

  const handleInputValueChange = (event, prevTotal) => {
    setPreviousEditValue(prevTotal);
    setEditValue(event.target.value);
  };

  const sortedItems: BudgetItem[] = useMemo(() => {
    return R.sort(
      (itemA, itemB) => itemA.name.localeCompare(itemB.name),
      budgetCategory.budgetItems,
    );
  }, [budgetCategory.budgetItems]);

  const scrollViewHeightRems: string = useMemo(() => {
    const itemRows: number = R.min(sortedItems.length, MAX_ROWS_PER_CATEGORY);
    return `${ITEM_ROW_HEIGHT * itemRows}rem`;
  }, [sortedItems]);

  const itemList = (
    <Virtuoso
      style={{ height: scrollViewHeightRems }}
      data={sortedItems}
      itemContent={index => {
        const budgetItem = sortedItems[index];
        return (
          <BudgetDetailItemRow
            key={budgetItem?.id}
            budgetItem={budgetItem}
            budgetCategory={budgetCategory}
            handleInputCellClick={handleInputCellClick}
            loadingItemUpdate={loadingItemUpdate}
            handleInputValueChange={handleInputValueChange}
            handleKeyUp={handleKeyUp}
            handleSaveField={handleSaveField}
            editValue={editValue}
            setEditValue={setEditValue}
          />
        );
      }}
    />
  );

  return (
    <>
      <ListTable fullHeight={false}>
        <TableBody>{itemList}</TableBody>
      </ListTable>
      <Modal
        open={confirmDraftFromEdit}
        onCancel={() => setConfirmDraftFromEdit(false)}
        onConfirm={() => handleRevertBudget()}
        title="Make Changes to Budget?"
        confirmButtonText="Revert to Draft"
      >
        <>
          <p>
            This will revert the budget unit to Draft, resetting the approval
            process and allowing users to make edits and resubmit.
          </p>
          <p>
            Please note: Reverting the status of lower-level units will also
            impact their higher-level units, if applicable.
          </p>
        </>
      </Modal>
    </>
  );
};

export default BudgetDetailItemTable;
